
import startRoutes from "./start-router";
import webRoutes from "./web-router";
import kontaktRoutes from "./kontakt-router";
import softwareRoutes from "./software-router";
import qdsRoutes from "./qds-router";
import shopRoutes from "./shop-router";

import impressumRoutes from "./impressum-router";
import datenschutzRoutes from "./datenschutz-router";
import certifikateRoutes from "./certifikate-router";

const routes = [
                ...startRoutes,
                ...webRoutes,
                ...kontaktRoutes,
                ...softwareRoutes,
                ...shopRoutes,
                ...impressumRoutes,
                ...datenschutzRoutes,
                ...certifikateRoutes,
                ...qdsRoutes,
            ];

export default routes;
