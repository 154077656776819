import { createApp } from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";
import Vuex from 'vuex';
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Vuex);
app.use(VueGtag, {
    config: { id: "G-7B83XZ4392" },
    params: {
      anonymize_ip: true
    },
    router,
  });
app.mount("#app");

