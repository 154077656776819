//import store from "../../store";
const softwareRoutes = [
  {
    path: "/Software",
    name:"Software",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Software.vue"
      ), 
      
  },
];

export default softwareRoutes;
