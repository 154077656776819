import { createRouter, createWebHistory } from "vue-router";
 
// import ShopPage from "@/pages/ShopPage.vue";
// import ReadProductPage from "@/pages/ReadProductPage.vue";
import routes from "./routes";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import store from "../store";

const router = createRouter({
  // Optionen
  history: createWebHistory(),
  mode: 'hash',
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)*",
      component: NotFoundPage,
      redirect: "/",
    },
  ],
});

router.afterEach((to) => {
  store.commit('setActiveSite',to.fullPath);
  document.title = 'pillarsoft.de'+to.fullPath || 'pillarsoft.de';
})

export default router;

// www.shop.de/auth/login
// www.shop.de/index.html/#/auth/login
