//import store from "../../store";
const certifikateRoutes = [
  {
    path: "/Certifikate",
    name:"Certifikate",
    component: () =>
      import(
        /*webpackChunkName: 'component-homepage' */ "@/pages/Certifikate.vue"
      ), 
      
  },
];

export default certifikateRoutes;
